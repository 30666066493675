export function isEntitySelected(
  entityId,
  entityGroups,
  allGroups,
  entityGroupIsSelected,
) {
  if (entityGroupIsSelected) return true;
  if (entityGroups <= 1) return false;
  return entityGroups.some((entityGroup) =>
    allGroups.some(
      (group) =>
        (group.selected &&
          entityGroup.id === group.id &&
          !group.entities.nodes.length) ||
        group.entities.nodes.filter((e) => e.id === entityId).length,
    ),
  );
}
