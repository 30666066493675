import { getMarkersStateFromNode } from '../getMarkersStateFromNode';

describe('getMarkersStateFromNode', () => {
  it('should return markers state from access network node', () => {
    const accessNetwork = {
      markers: {
        ids: ['1', '2', '3'],
        all: false,
      },
      organizations: {
        ids: ['4', '5', '6'],
        all: true,
      },
    };

    const result = getMarkersStateFromNode(accessNetwork);

    expect(result).toEqual({
      excludeMarkers: [],
      excludeOrganizations: [],
      canAccessAllMarkers: false,
      canAccessAllOrganizations: false,
      markers: ['1', '2', '3'],
      markersTotalCount: 0,
      organizations: ['4', '5', '6'],
      organizationsTotalCount: 0,
    });
  });

  it('should return markers state from access network node with all markers', () => {
    const accessNetwork = {
      markers: {
        ids: [],
        all: true,
      },
      organizations: {
        ids: [],
        all: false,
      },
    };

    const result = getMarkersStateFromNode(accessNetwork);

    expect(result).toEqual({
      excludeMarkers: [],
      excludeOrganizations: [],
      canAccessAllMarkers: false,
      canAccessAllOrganizations: false,
      markers: [],
      markersTotalCount: 0,
      organizations: [],
      organizationsTotalCount: 0,
    });
  });

  it('should return markers state from access network node with all organizations', () => {
    const accessNetwork = {
      markers: {
        ids: [],
        all: false,
      },
      organizations: {
        ids: [],
        all: true,
      },
    };

    const result = getMarkersStateFromNode(accessNetwork);

    expect(result).toEqual({
      markers: [],
      excludeMarkers: [],
      canAccessAllMarkers: false,
      markersTotalCount: 0,
      organizations: [],
      excludeOrganizations: [],
      canAccessAllOrganizations: false,
      organizationsTotalCount: 0,
    });
  });
});
