/**
 * @typedef {{
 *  markers: {
 *    ids: string[];
 *    exceptionIds: string[];
 *    all: boolean;
 *  },
 *  organizations: {
 *    ids: string[];
 *    all: boolean;
 *    exceptionIds: string[];
 *  }
 * }} Recipients
 */

/**
 * @param {import('../../../../app/modules/Permissions/hooks/useSelectRecipientModal').markersState} markersState
 * @param {boolean} communicationToAll
 */
export function getAccessNetwork(markersState, communicationToAll) {
  if (communicationToAll) {
    return {
      communicationToAll: true,
    };
  }

  let markers = {};

  let organizations = {};

  if (markersState.canAccessAllMarkers) {
    /** @type {string[]} */
    markers.ids = [];
    markers.exceptionIds = markersState.excludeMarkers.map((marker) =>
      String(marker.id),
    );
    markers.all = true;
  } else {
    markers.ids = markersState.markers;
    markers.exceptionIds = [];
    markers.all = false;
  }

  if (markersState.canAccessAllOrganizations) {
    organizations.ids = [];
    organizations.exceptionIds = markersState.excludeOrganizations.map(
      (organization) => String(organization.id),
    );
    organizations.all = true;
  } else {
    organizations.ids = markersState.organizations;
    organizations.exceptionIds = [];
    organizations.all = false;
  }

  return {
    markers,
    organizations,
  };
}
