/**
 * @typedef {{
 *  markers: {
 *    ids: string[];
 *    exceptionIds: string[];
 *    all: boolean;
 *  },
 *  organizations: {
 *    ids: string[];
 *    all: boolean;
 *    exceptionIds: string[];
 *  }
 * }} Recipients
 */

/**
 * @param {import('../../../../app/modules/Permissions/hooks/useSelectRecipientModal').markersState} markersState
 * @param {boolean} communicationToAll
 */
export function getEditAccessNetwork(markersState, communicationToAll) {
  if (communicationToAll) {
    return {
      communicationToAll: true,
    };
  }

  let markers = {};

  let organizations = {};

  if (markersState.canAccessAllMarkers) {
    /** @type {string[]} */
    const exceptionIds =
      markersState.excludeMarkers.map((marker) => String(marker.id)) ?? [];
    if (!exceptionIds.length) {
      markers.canAccessAllMarkers = true;
    } else {
      markers.exceptionIds = exceptionIds;
    }
  } else {
    markers.ids = markersState.markers;
  }

  if (markersState.canAccessAllOrganizations) {
    const exceptionIds =
      markersState.excludeOrganizations.map((organization) =>
        String(organization.id),
      ) ?? [];

    if (!exceptionIds.length) {
      organizations.canAccessAllOrganizations = true;
    } else {
      organizations.exceptionIds = exceptionIds;
    }
  } else {
    organizations.ids = markersState.organizations;
  }

  return {
    communicationToAll: false,
    markers,
    organizations,
  };
}
