import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader, Segment, Image, Tab, Menu, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { cloneDeep, find } from 'lodash';
import cookie from 'react-cookie';

import ListView from '../../../components/ListView';
import Page from '../../../components/Page';
import ColoredCheckbox from '../../../components/ColoredCheckbox';
import BetaModal from '../../../components/ui/Modal';
import FeatureToogleBanner from '../../../components/FeatureToogleBanner';
import FeatureAnnouncement from '../../../components/ui/FeatureAnnouncement';
import SideBanner from '../../../components/SideBanner';
import MessageItem from '../../Message/Item';
import MessageFilter from '../../Message/Filter';
import MessageDraft from '../../Message/Draft';
import NewTerms from '../../Terms/Index';
import MultipleAutosuggest from './MultipleAutosuggest';
import { __ } from '../../../i18n';
import * as utils from '../../../utils';
import { sendGoogleAnalyticsEvent } from '../../../lib/analytics';
import Messages from '../../Message/utils/validateListMessages';
import {
  canSeeFeatureAnnouncement,
  setCookie,
  handleFeatureAnnouncementAction,
  fetchStaffList,
  updateLocationQueryRecipientIds,
  onClickBetaModalActionButton,
} from './messagesUtils';
import { hasCommunicationStatusFeedback } from '../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback';
// import ChatNews from '../../../app/Presentation/ChatNews/ViewUI'
/// import { shouldShowChatNewsModal } from '../../../app/Presentation/ChatNews/ViewModel';


const PAGE_SIZE = 25;
const SELECTION_LIMIT = 100;

const styles = {
  menu: {
    position: 'fixed',
    bottom: 0,
    top: 'inherit',
    width: 'max-content',
    zIndex: '100',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  mobileTab: {
    padding: '0px 0px 24px 0px',
    overflowX: 'auto',
    overflowY: 'hidden'
  }
};

const entitySearchQuery = gql`
  query EntitySearchQuery($id: ID!, $search: String, $limit: Int) {
  node (id: $id) {
    ... on Entity {
      id: dbId
      recipients(search: $search, getSenders: true, limit: $limit) {
        nodes {
          id: dbId
          fullname
          picture {
            uri
            id: dbId
            key
          }
        }
      }
    }
  }
}`;

@inject('store', 'client')
@graphql(gql`query EntityMessagesQuery (
  $entityId: ID!,
  $limit: Int,
  $offset: Int,
  $folder: EntityMessagesFolder,
  $search: String,
  $attachments: [MessageAttachment],
  $recipientIds: [Int],
  $labelIds: [ID],
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        selected
        messages(
          limit: $limit,
          offset: $offset,
          folder: $folder,
          search: $search,
          attachments: $attachments,
          recipientIds: $recipientIds,
          labelIds: $labelIds
        ) {
          nodes {
            id: dbId
            selected
            statusText
            statusSend(entityId: $entityId)
            notifications {
              totalCount
            }
            review {
              id
              user {
                id: dbId
                fullname
              }
              reviewStatus
              reviewIn
            }
            ... MessageItemEntity
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
        sendLater: messageDrafts(type: DRAFT, hasSendAt: true) {
          totalCount
        }
        drafts: messageDrafts(type: DRAFT, hasSendAt: false) {
        totalCount
        }
        unreadMessages: messages(folder: UNREAD_BY_NTF) {
          totalCount
        }
        messagesToApprove {
          totalCount
        }
        organization {
          id: dbId
          labels(limit: 100) {
            nodes {
              id: dbId
              title
              color
            }
          }
        }
      }
    }
  }
  ${MessageItem.fragments.entityMessage}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    pollInterval: ownProps.location.query.type ? null : 3 * 60 * 1000,
    variables: {
      entityId: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      offset: 0,
      folder: ownProps.location.query.folder || null,
      search: ownProps.location.query.search || '',
      attachments: (ownProps.location.query.attachments && JSON.parse(ownProps.location.query.attachments)) || [],
      recipientIds: (ownProps.location.query.recipientIds && JSON.parse(ownProps.location.query.recipientIds)) || null,
      labelIds: (ownProps.location.query.labelIds && JSON.parse(ownProps.location.query.labelIds)) || [],
    }
  }),
  skip: ownProps => ownProps.location.query.type || ownProps.location.query.folder === 'DELETED'
})
@graphql(gql`query EntitySendLaterQuery (
  $entityId: ID!,
  $limit: Int,
  $offset: Int,
  $search: String
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        sendLater: messageDrafts(type: DRAFT, hasSendAt: true, limit: $limit, offset: $offset, search: $search, orderBy: [{ column: SEND_AT, direction: DESC }, { column: ID, direction: DESC }]) {
          nodes {
            id: dbId
            sendAt
            input
            ... MessageDraft
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
         }
        drafts: messageDrafts(type: DRAFT, hasSendAt: false) {
          totalCount
        }
        messagesToApprove {
          totalCount
        }
      }
    }
  }
  ${MessageDraft.fragments.draft}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      offset: 0,
      search: '',
    }
  }),
  skip: ownProps => ownProps.location.query.type !== 'sendLater'
})
@graphql(gql`query EntityDraftsQuery (
  $entityId: ID!,
  $limit: Int,
  $offset: Int,
  $search: String
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
         drafts: messageDrafts(type: DRAFT, hasSendAt: false, limit: $limit, offset: $offset, search: $search) {
            nodes {
              id: dbId
              sendAt
              input
              ... MessageDraft
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
          }
          sendLater: messageDrafts(type: DRAFT, hasSendAt: true) {
            totalCount
          }
          messagesToApprove {
            totalCount
          }
      }
    }
  }
  ${MessageDraft.fragments.draft}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      offset: 0,
      search: '',
    }
  }),
  skip: ownProps => ownProps.location.query.type !== 'drafts'
})
@graphql(gql`
  query EntityMessagesToApprove (
    $limit: Int,
    $offset: Int,
    $entityId: ID!,
    $authorIds: [ID],
  ) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        messagesToApprove(limit: $limit, offset: $offset, authorIds: $authorIds) {
            nodes {
              id
              user {
                id: dbId
                fullname
              }
              entity {
                id: dbId
                fullname
              }
              summary
              subject
              content
              input
              helpers
              toEntity {
                id: dbId
                fullname
                status
                picture {
                  uri
                  id: dbId
                  key
                }
              }
              created
              modified
              deleted
              reportsCount
              recipientsCount
              noReply
              imagesCount
              videosCount
              audiosCount
              filesCount
              commitmentsCount
              signaturesCount
              surveysCount
              chargesCount
              reportsCount
              formsCount
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
            totalCount
          }
          drafts: messageDrafts(type: DRAFT, hasSendAt: false) {
            totalCount
          }
          sendLater: messageDrafts(type: DRAFT, hasSendAt: true) {
            totalCount
          }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      offset: 0,
      authorIds: (
        ownProps.location.query.recipientIds && JSON.parse(ownProps.location.query.recipientIds)
      ) || [],
    }
  }),
  skip: ownProps => ownProps.location.query.type !== 'messagesToApprove' || Messages.skipListMessages(ownProps.location.pathname, ownProps.location.query)
})
@graphql(gql`query EntityDeletedMessagesQuery (
  $entityId: ID!,
  $limit: Int,
  $offset: Int,
  $folder: EntityMessagesFolder,
  $search: String,
  $attachments: [MessageAttachment],
  $recipientIds: [Int],
  $labelIds: [ID]
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        selected
        messages(limit: $limit, offset: $offset, folder: $folder, search: $search, attachments: $attachments, recipientIds: $recipientIds, labelIds: $labelIds) {
          nodes {
            id: dbId
            selected
            statusText
            notifications {
              totalCount
            }
            ... MessageDeletedItemEntity
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
        sendLater: messageDrafts(type: DRAFT, hasSendAt: true) {
          totalCount
        }
        drafts: messageDrafts(type: DRAFT, hasSendAt: false) {
        totalCount
        }
        unreadMessages: messages (folder: UNREAD_BY_NTF) {
          totalCount
        }
        organization {
          id: dbId
          labels {
            nodes {
              id: dbId
              title
              color
            }
          }
        }
      }
    }
  }
  ${MessageItem.fragments.entityDeletedMessage}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    pollInterval: ownProps.location.query.type ? null : 3 * 60 * 1000,
    variables: {
      entityId: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      offset: 0,
      folder: ownProps.location.query.folder || null,
      search: ownProps.location.query.search || '',
      attachments: (ownProps.location.query.attachments && JSON.parse(ownProps.location.query.attachments)) || [],
      recipientIds: (ownProps.location.query.recipientIds && JSON.parse(ownProps.location.query.recipientIds)) || null,
      labelIds: (ownProps.location.query.labelIds && JSON.parse(ownProps.location.query.labelIds)) || []
    }
  }),
  skip: ownProps => ownProps.location.query.type || ownProps.location.query.folder !== 'DELETED'
})
@graphql(gql`mutation updateRecipient($updateRecipientInBatchInput: updateRecipientInBatchInput!) {
  updateRecipientInBatch(input: $updateRecipientInBatchInput) {
    clientMutationId
  }
}`, {
  name: 'updateRecipient',
  options: {
    refetchQueries: ['EntityMessagesQuery'],
    awaitRefetchQueries: true
  }
})
@graphql(gql`mutation updateOrganization($updateOrganizationMutation: UpdateOrganizationInput!) {
  updateOrganization(input: $updateOrganizationMutation) {
    clientMutationId
  }
}`, {
  name: 'updateOrganization',
}
)
@graphql(gql`mutation deleteForMe($CreateMessageStatusInBatchInput: CreateMessageStatusInBatchInput!) {
  createMessageStatusInBatch(input: $CreateMessageStatusInBatchInput) {
    clientMutationId
    __typename
  }
}`, {
  name: 'deleteForMe',
  options: {
    refetchQueries: ['EntityMessagesQuery'],
    awaitRefetchQueries: true
  }
})
@graphql(gql`mutation recoverForMe($DeleteMessageStatusInBatchInput: DeleteMessageStatusInBatchInput!) {
  deleteMessageStatusInBatch(input: $DeleteMessageStatusInBatchInput) {
    clientMutationId
    __typename
  }
}`, {
  name: 'recoverForMe',
  options: {
    refetchQueries: ['EntityMessagesQuery'],
    awaitRefetchQueries: true
  }
})
@graphql(gql`mutation sendBannerMQL($organizationId: ID, $bannerKey: String) {
  sendBannerSlackMQL(organizationId: $organizationId, bannerKey: $bannerKey) {
    result
  }
}`, {
  name: 'sendBannerSlackMQL',
})
@graphql(gql`mutation approveMessageToApprove($entityId: ID!, $messageId: ID!) {
 approveMessageToApprove(input: {entityApproverId: $entityId, messageToApproveId: $messageId}) {
    messageToApprove {
      id
    }
  }
}`, {
  name: 'approveMessageToApprove',
  options: {
    refetchQueries: ['EntityMessagesToApprove'],
    awaitRefetchQueries: true
  }
})
@graphql(gql`mutation rejectMessageToApprove($entityId: ID!, $messageId: ID!) {
  rejectMessageToApprove(input: {entityApproverId: $entityId, messageToApproveId: $messageId}) {
    messageToApprove {
      id
    }
   }
 }`, {
  name: 'rejectMessageToApprove',
  options: {
    refetchQueries: ['EntityMessagesToApprove'],
    awaitRefetchQueries: true
  }
})
@observer
export default class EntityMessages extends Page {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      margin: false,
      featureAnnouncementModal: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { store, location } = this.props;
    const oldQuery = location && location.query && location.query;
    const newQuery = prevProps && prevProps.location && prevProps.location.query;
    const entityId = store && store.entity && store.entity.id;

    if (!!entityId && entityId !== this.lastEntityId) {
      this.lastEntityId = entityId;
      this.validateShowBannerToEntity();
    }

    if (newQuery.recipientIds && newQuery.recipientIds.length && oldQuery.type !== newQuery.type) {
      updateLocationQueryRecipientIds(location, [])
    }
  }
  /* componentDidMount() {
    const shouldShow = shouldShowChatNewsModal(this.props.store);
    this.setState({ shouldShowConversationModal: shouldShow });
  } */

  renderRow = (message, key) => {
    return (
      <MessageItem
        key={message.id || key}
        message={message}
        search={this.props.location.query.search}
        to={`/entities/${this.props.params.entity_id}/messages/${message.id}${message.unread > 0 ? '?forceFetch=true' : ''}/${this.props.location.search}`}
        onToggle={this.userHasEntity() && (() => this.onToggle(message.id))}
        disabled={message.selected ? false : this.isDisabled()}
        hasPopup={message.selected ? false : this.isDisabled()}
        hasCommunicationStatusFeedback={hasCommunicationStatusFeedback(this.props.store.currentOrganization)}
        statusSend={message.statusSend}
      />
    );
  };

  renderWritterRow = (message, key) =>
    <MessageItem
      key={message.id || key}
      message={message}
      search={this.props.location.query.search}
      to={`/entities/${this.props.params.entity_id}/messages/${message.id}${message.unread > 0 ? '?forceFetch=true' : ''}/${this.props.location.search}`}
      disabled={message.selected ? false : this.isDisabled()}
      hasPopup={message.selected ? false : this.isDisabled()}
      isMessageToApprove
    />;

  renderApprovalRow = (message, key) => {
    const { approveMessageToApprove, rejectMessageToApprove, params } = this.props;

    return (
      <MessageItem
        key={message.id || key}
        isApproval
        message={message}
        search={this.props.location.query.search}
        to={`/entities/${this.props.params.entity_id}/messages/${message.id}${message.unread > 0 ? '?forceFetch=true' : ''}/${this.props.location.search}`}
        disabled={message.selected ? false : this.isDisabled()}
        hasPopup={message.selected ? false : this.isDisabled()}
        handleApprove={async () => {
          this.setState({ loading: true });
          await approveMessageToApprove({
            variables: {
              entityId: params.entity_id,
              messageId: message.id,
            }
          });
          this.setState({ loading: false });
        }}
        goToEdit={`/entities/${this.props.params.entity_id}/messages/${message.id}/edit${message.unread > 0 ? '?forceFetch=true' : ''}/${this.props.location.search}`}
        handleReprove={async () => {
          this.setState({ loading: true });
          await rejectMessageToApprove({
            variables: {
              entityId: params.entity_id,
              messageId: message.id,
            }
          });
          this.setState({ loading: false });
        }}
      />)
  }

  renderDraftRow = (draft, key) => {
    const { params, store } = this.props;
    const { fwMessageId } = JSON.parse(draft.input);
    const userHasEntity = params.entity_id && store.currentUser.entities &&
      (store.currentUser.entities?.filter(entity => entity.code && entity.id && entity.id.toString() === params.entity_id)?.length > 0);

    return (<MessageDraft
      key={key}
      draft={draft}
      to={userHasEntity ? `/entities/${this.props.params.entity_id}/messages/${draft.id}/edit` + (fwMessageId ? `?fwMessageId=${fwMessageId}` : '') : null}
    />);
  }

  renderTrashRow = (message, key) =>
    <MessageItem
      deleted
      userHasEntity={this.userHasEntity()}
      key={message.id || key}
      message={message}
      search={this.props.location.query.search}
      to={`/entities/${this.props.params.entity_id}/messages/${message.id}${message.unread > 0 ? '?forceFetch=true' : ''}/${this.props.location.search}&deletedForMe=true`}
      onToggle={this.userHasEntity() && (() => this.onToggle(message.id))}
      disabled={message.selected ? false : this.isDisabled()}
      hasPopup={message.selected ? false : this.isDisabled()}
    />

  onSelectAll = () => {
    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.selected = !nextResult.node.selected;

      let selectedCount = data?.node?.messages?.nodes?.reduce((acc, m) => {
        if (m.selected) return acc + 1;
        return acc;
      }, 0);

      nextResult?.node?.messages?.nodes?.forEach((message) => {
        if (nextResult?.node?.selected && SELECTION_LIMIT - selectedCount > 0) {
          if (!message.selected) {
            message.selected = true;
            selectedCount++;
          }
        } else if (!nextResult?.node?.selected) message.selected = false;
      });

      return nextResult;
    });
  }

  onToggle = (message_id) => {
    const { data } = this.props;

    const finalIndex = data?.node?.messages?.nodes?.findIndex(sm => sm.id === message_id);

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.messages.nodes[finalIndex].selected =
        !nextResult?.node?.messages?.nodes[finalIndex].selected;

      const selectedCount = nextResult?.node?.messages?.nodes?.reduce((acc, m) => {
        if (m.selected) return acc + 1;
        return acc;
      }, 0);
      if (selectedCount >= SELECTION_LIMIT) nextResult.node.selected = true;
      else nextResult.node.selected = false;

      return nextResult;
    });
  }

  isDisabled = () => {
    const { data } = this.props;

    const selectedCount = data?.node?.messages?.nodes?.reduce((acc, m) => {
      if (m.selected) return acc + 1;
      return acc;
    }, 0);

    return selectedCount >= SELECTION_LIMIT;
  }

  deleteMultipleMessages = async (messages, hasUndoOption = true) => {
    this.setState({ loading: true });
    const { params, store, deleteForMe } = this.props;
    const messagesId = messages.map(message => message.id);

    try {
      await deleteForMe({
        variables: {
          CreateMessageStatusInBatchInput: {
            messagesId,
            entityId: params.entity_id,
            status: 'DELETED'
          }
        }
      });

      utils.clearSnackbar(store);
      store.snackbar = {
        active: true,
        message: hasUndoOption ? messages?.length > 1 ? __('%s messages deleted', messages?.length) : __('Message was deleted') : messages?.length > 1 ? __('Actions successfully undone') : __('Action successfully undone'),
        success: true,
        dismissAfter: 10000,
        onClick: hasUndoOption ? () => this.recoverMultipleMessages(messages, false) : null,
        action: hasUndoOption ? __('Undo') : __('Close')
      };

      this.props.store.appends.pop();
    } catch (e) {
      console.error(e);

      utils.clearSnackbar(store);
      store.snackbar = { active: true, message: __('An error occurred while deleting message'), success: false, dismissAfter: 5000 };
    }

    await this.props.data.refetch();
    await this.props.client.resetStore();

    this.setState({ loading: false });
  }

  recoverMultipleMessages = async (messages, hasUndoOption = true) => {
    this.setState({ loading: true });
    const { params, store, recoverForMe } = this.props;
    const messagesId = messages.map(message => message.id);

    try {
      await recoverForMe({
        variables: {
          DeleteMessageStatusInBatchInput: {
            messagesId,
            entityId: params.entity_id
          }
        }
      });

      utils.clearSnackbar(store);
      store.snackbar = {
        active: true,
        message: hasUndoOption ? messages?.length > 1 ? __('%s messages recovered', messages?.length) : __('Message recovered') : __('Action successfully undone'),
        success: true,
        dismissAfter: 10000,
        onClick: hasUndoOption ? () => this.deleteMultipleMessages(messages, false) : null,
        action: hasUndoOption ? __('Undo') : __('Close')
      };

      this.props.store.appends.pop();
    } catch (e) {
      console.error(e);

      utils.clearSnackbar(store);
      store.snackbar = {
        active: true,
        message: __('An error occurred while recovering message'),
        success: false,
        dismissAfter: 5000
      };
    }

    await this.props.data.refetch();
    await this.props.client.resetStore();

    this.setState({ loading: false });
  }

  /**
   * @param {'READ' | 'AS_UNREAD' | 'ARCHIVED' | 'UNARCHIVED'} to
   * @param {{}[]} messages
   * @param {boolean} [hasUndoOption]
   * @returns {Promise<void>}
   */
  updateStatus = async (to, messages, hasUndoOption = true) => {
    const { params, updateRecipient } = this.props;

    const entityInUser = !!(find(this.props.store.currentUser.entities.slice(), entity => entity.id == this.props.store.entity.id));
    if (!entityInUser) {
      const snackbar = { active: true, message: __('Only users in the profile can perform this action'), success: false, action: __('Close'), dismissAfter: 10000 };
      this.props.store.snackbar = snackbar;

      return;
    }

    this.setState({ loading: true });

    const needsToUpdate = (message) => {
      const cases = {
        READ: message.statusText !== 'READ' || message.unread !== null
          || (message.notifications && message.notifications.totalCount > 0) || !hasUndoOption,
        AS_UNREAD: !['RECEIVED', 'AS_UNREAD'].includes(message.status) || !hasUndoOption,
        ARCHIVED: true, // always needs to update
        UNARCHIVED: true,
      };
      return cases[to];
    };

    const messagesToUpdate = messages?.filter(message => needsToUpdate(message));

    const messagesId = messagesToUpdate.map(message => message.id);

    utils.clearSnackbar(this.props.store);

    await updateRecipient({
      variables: {
        updateRecipientInBatchInput: {
          messagesId,
          entityId: params.entity_id,
          deleteNotification: true,
          status: to,
          legacyMode: false,
        }
      }
    });

    await this.props.data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      messagesToUpdate.forEach((message) => {
        if (message.unread !== null || (message.notifications && message.notifications.totalCount > 0)) {
          nextResult.node.unreadMessages.totalCount -= 1;
        }
      });
      return nextResult;
    });

    await this.props.data.refetch();
    await this.props.client.resetStore();

    this.setState({ loading: false });

    if (to !== 'UNARCHIVED') {
      messagesToUpdate.forEach((message) => {
        message.statusText = to;
      });
    }

    let message;
    let onClick;

    switch (to) {
      case 'READ':
        message = messagesToUpdate?.length > 1 ? __('%s messages marked as read', messagesToUpdate?.length) : __('Message marked as read');
        onClick = () => this.updateStatus('AS_UNREAD', messagesToUpdate, false);
        break;
      case 'AS_UNREAD':
        message = messagesToUpdate?.length > 1 ? __('%s messages marked as unread', messagesToUpdate?.length) : __('Message marked as unread');
        onClick = () => this.updateStatus('READ', messagesToUpdate, false);
        break;
      case 'ARCHIVED':
        message = messagesToUpdate?.length > 1 ? __('%s messages archived', messagesToUpdate?.length) : __('Message archived');
        onClick = () => this.updateStatus('UNARCHIVED', messagesToUpdate, false);
        break;
      case 'UNARCHIVED':
        message = messagesToUpdate?.length > 1 ? __('%s messages moved to inbox', messagesToUpdate?.length) : __('Message moved to inbox');
        onClick = () => this.updateStatus('ARCHIVED', messagesToUpdate, false);
        break;
      default:
    }

    if (!hasUndoOption) message = messagesToUpdate?.length > 1 ? __('Actions successfully undone') : __('Action successfully undone');

    const snackbar = { active: true, message, success: true, action: hasUndoOption ? __('Undo') : __('Close'), dismissAfter: 10000 };
    if (hasUndoOption) snackbar.onClick = onClick;

    this.props.store.snackbar = snackbar;
  }

  onTabChange = (e, data) => {
    this.onUniqueParameterChange('type', data.panes[data.activeIndex].value);
  }

  userHasEntity = () => {
    const { store } = this.props;
    return (store.currentUser ? store.currentUser.entities : [])
      ?.filter(entity => entity.id && entity.id.toString() === this.props.params.entity_id)
      ?.length > 0;
  }

  validateShowBannerToEntity = async () => {
    const { store } = this.props;
    const { entity, currentUser, currentOrganization, unleash } = store;

    this.setState({ banner: null });

    /* const hasArrivedEnabled = currentOrganization && !!currentOrganization.productFeatures.nodes.filter((item) => item.identifier === 'arrived')?.length;
    const usersHasSeenBanner = this.transformCookiesUserListBannerView();

    if (this.isMobile() || entity.type == 'STUDENT' || usersHasSeenBanner.includes(currentUser.id) || hasArrivedEnabled || !isPortuguese) return false;
    if (entity && entity.type == 'ADMIN') return true;
    if (entity && (entity.fullname.toLowerCase().includes('porta') || entity.fullname.toLowerCase().includes('saida') || entity.fullname.toLowerCase().includes('saída'))) return true;
    return false; */

    setTimeout(async () => {
      const isPortuguese = currentUser && currentUser.language && currentUser.language.includes('pt');
      const hasSeenBannerVersion = cookie.load('hasSeenBannerVersion');
      const usersHasSeenBanner = this.transformCookiesUserListBannerView();
      const variant = currentOrganization && unleash && await unleash.getVariant('right-banner-walle', { organizationId: [currentOrganization.id] });
      const value = variant && variant.value;
      if (
        isPortuguese
        && value
        && (!value.entitiesTypeFilter || (value.entitiesTypeFilter && value.entitiesTypeFilter.includes(entity.type)))
        && currentUser
        && !(String(hasSeenBannerVersion) == String(value.version) && usersHasSeenBanner.includes(currentUser.id))
        && !this.isMobile()
      ) this.setState({ banner: value });
    }, 1000);
  }

  transformCookiesUserListBannerView = () => {
    const usersHasSeenBanner = cookie.load('hasSeenBannerUserId');
    const usersHasSeenBannerConverted = Array.from(usersHasSeenBanner || []);
    return usersHasSeenBannerConverted;
  }

  saveBannerView = (version, userId) => {
    const usersHasSeenBannerConverted = this.transformCookiesUserListBannerView();

    usersHasSeenBannerConverted.push(userId);

    cookie.save('hasSeenBannerVersion', version);
    cookie.save('hasSeenBannerUserId', usersHasSeenBannerConverted);
    this.setState({ banner: null, showModal: false });
  }

  getOrgsWithoutNews = () => {
    const { env } = this.props.store.app;

    const isProd = ['production', 'beta'].includes(env);
    const isStage = env === 'stage';

    // Insert here the ids of the organizations that doesn't want to have access to the News
    const PRODUCTION_ORGS_WITHOUT_NEWS = [436, 5511, 6134];
    const STAGE_ORGS_WITHOUT_NEWS = [1667, 1729, 1783, 1819, 1898, 2084, 2091, 2444, 2446, 2808, 5024, 5032, 95607];
    const LOCAL_ORGS_WITHOUT_NEWS = [1];

    if (isProd) return PRODUCTION_ORGS_WITHOUT_NEWS;
    else if (isStage) return STAGE_ORGS_WITHOUT_NEWS;
    return LOCAL_ORGS_WITHOUT_NEWS;
  }

  validateShowFeatureToogle() {
    const { store } = this.props;
    const { currentEntity, currentUser, currentOrganization, unleash } = store;
    const { id: organizationId } = currentOrganization || {};

    if (!currentOrganization) return false;

    const hasSeenFeatureToogle = cookie.load('hasSeenFeatureToogle') && String(cookie.load('hasSeenFeatureToogle')).split(',').includes(String(currentEntity.id));

    const hasSendFeatureToggleToGA = cookie.load('hasSeendFeatureToogleToGA');
    if (hasSeenFeatureToogle && !hasSendFeatureToggleToGA) {
      cookie.save('hasSeendFeatureToogleToGA', 1);
      sendGoogleAnalyticsEvent({
        name: 'FeatureToggle',
        category: `clicked-${this.props.store.app.env}`,
        label: `OrganizationId: ${
          organizationId
        }; userId: ${
          store.currentUser && store.currentUser.id
        }; entityId: ${
          store.currentEntity && store.currentEntity.id
        };`
      }, { store });
    }

    const hasPermission = (currentUser.isMaster || utils.parseScopeToForm(currentEntity.scope).organization.update || currentEntity.type === 'ADMIN');
    return hasPermission && !this.isMobile() && !hasSeenFeatureToogle && !(currentOrganization.confRepliesStudent) && (unleash && unleash.isReady && unleash.getStatus('student-replies'));
  }

  handleFeatureToogleCookie() {
    const { id } = this.props.store.currentEntity;
    const options = {
      path: '/',
      domain: this.props.store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''),
      maxAge: 60 * 60 * 24 * 365,
    }
    const hasSeenFeatureToogle = cookie.load('hasSeenFeatureToogle');

    if (!hasSeenFeatureToogle) cookie.save('hasSeenFeatureToogle', id, options);
    else cookie.save('hasSeenFeatureToogle', String(hasSeenFeatureToogle).split(',').concat(id).join(','), options);
  }

  renderAnnouncementModal = () => {
    const { store } = this.props;
    const { entity, currentUser } = this.props.store;

    const newFeature = entity.organization && entity.organization.plan === 'PREMIUM' ? {
      id: 'ApprovalMessageAdmin',
      title: __('The message approver has arrived'),
      content: __('Count on an extra verification step to ensure the unity of communication, send updated information, and review possible textual errors.'),
      link: 'https://5967222.fs1.hubspotusercontent-na1.net/hubfs/5967222/fluxo%20de%20aprova%C3%A7%C3%A3o%20-%20manual.pdf',
      submitButtonText: __('Learn how to activate'),
      image:'/images/Approve-Message-Premium.svg',
    } : (entity.organization && entity.organization.plan === 'STANDARD' || entity.organization.plan === 'STANDARD_NEW' ) && {
      id: 'ApprovalMessageAdminStandard',
      title: __('The message approver has arrived'),
      content: __("Count on an extra verification step to ensure your school's level of communication excellence. Talk to us to bring this solution to your school!"),
      link: 'https://conteudo.classapp.com.br/seja-premium',
      submitButtonText: __('I want to know more!'),
      image:'/images/Approve-Message-Standard.png',
    }

    return (
      <FeatureAnnouncement
        title={newFeature.title}
        content={newFeature.content}
        submitButtonText={newFeature.submitButtonText}
        image={newFeature.image}
        onClickActionButton={()=> handleFeatureAnnouncementAction(newFeature, entity, currentUser, this.props)}
        onClose={()=> setCookie(newFeature.id, this.props)}
        open={() => canSeeFeatureAnnouncement()}
        onShow = {() => {
          if (entity.organization && entity.organization.id) {
            sendGoogleAnalyticsEvent({
              name: 'ApprovalMessageAnnouncement',
              category: (entity.organization && ['STANDARD', 'STANDARD_NEW'].includes(entity.organization.plan))
                ? 'Shown administrative modal standard'
                : 'Shown administrative modal',
              label: `OrganizationID: ${
                entity.organization.id
              } OrganizationFullname: ${
                entity.organization.fullname
              } userId: ${
                currentUser.id
              } userFullname: ${
                currentUser.fullname
              }`,
            }, { store });
          }
        }}
      />
    )
  }

  render() {
    const { data, params, location, store, updateOrganization, client } = this.props;
    const { banner, showModal } = this.state;
    const { currentUser, currentOrganization } = store;
    const { features, id: organizationId } = currentOrganization || {};
    const panes = [{ menuItem: __('Inbox'), value: '', index: 0 }];

    const isMessageApprover = utils.isMessageApprover({ store });
    const isMessageWriter = utils.isMessageWriter({ store })

    if (Messages.skipListMessages(location.pathname, location.query)) {
      return this.props.children && React.cloneElement(this.props.children, {});
    }

    if (!data || (data.loading && !data.node) || !data.node || this.state.loading) {
      if (!data) console.log('!data');

      return (
        <div id="EntityMessages">
          <Loader active inline="centered" />
        </div>
      );
    }

    if ((location.query.type && location.query.type === 'messagesToApprove' && !isMessageApprover) || isMessageWriter && data?.node?.messagesToApprove?.totalCount) {
      panes.push({ menuItem: `${__('Awaiting approval')} (${data?.node?.messagesToApprove?.totalCount || 0})`, value: 'messagesToApprove', index: panes?.length });
    }

    if ((location.query.type && location.query.type === 'messagesToApprove' && !isMessageWriter) || isMessageApprover && data?.node?.messagesToApprove?.totalCount) {
      panes.push({ menuItem: `${__('To Approve')} (${data?.node?.messagesToApprove?.totalCount || 0})`, value: 'messagesToApprove', index: panes?.length });
    }

    if (data?.node?.sendLater?.totalCount || (location.query.type && location.query.type === 'sendLater')) panes.push({ menuItem: __('Send Later'), value: 'sendLater', index: panes?.length });
    if (data?.node?.drafts?.totalCount || (location.query.type && location.query.type === 'drafts')) panes.push({ menuItem: __('Draft'), value: 'drafts', index: panes?.length });

    // TODO Check if activeType is never falsy
    const activeType = !location.query.type ? data?.node.messages : data?.node[location.query.type];
    const selected = activeType?.nodes?.filter(message => message.selected);

    const items = [];

    const userHasEntity = this.userHasEntity();

    if (userHasEntity && location.query.folder !== 'DELETED') {
      if (location.query.folder !== 'ARCHIVE') {
        items.push(<Menu.Item onClick={() => this.updateStatus('ARCHIVED', selected)} icon="archive">
          {__('Archive')}
        </Menu.Item>);
      } else {
        items.push(<Menu.Item onClick={() => this.updateStatus('UNARCHIVED', selected)} icon="envelope open">
          {__('Move to inbox')}
        </Menu.Item>);
      }

      if (selected?.filter(message => message.unread !== null || (message.notifications && message.notifications.totalCount > 0))?.length) {
        items.push(<Menu.Item onClick={() => this.updateStatus('READ', selected)} icon="envelope open">
          {__('Mark as read')}
        </Menu.Item>);
      } else {
        items.push(<Menu.Item onClick={() => this.updateStatus('AS_UNREAD', selected)} icon="envelope">
          {__('Mark as unread')}
        </Menu.Item>);
      }

      if ((features && features.deleteMessages) || store.currentUser.isMaster) {
        items.push(<Menu.Item
          onClick={() => this.deleteMultipleMessages(selected)}
        >
          {__('Delete for me')}
        </Menu.Item>);
      }
    } else if (userHasEntity) {
      items.push(<Menu.Item
        onClick={() => this.recoverMultipleMessages(selected)}
      >
        {__('Recover')}
      </Menu.Item>);
    }
    const showBanner = !!banner && banner.key && banner.version && banner.disclaimer && banner.url;

    const showFeatureToogle = this.validateShowFeatureToogle();
    if (showBanner && !this.seeEventNotFired) {
      this.seeEventNotFired = true;
      sendGoogleAnalyticsEvent({
        name: 'RightBanner',
        category: `see-${banner.key}-${this.props.store.app.env}`,
        label: `OrganizationId: ${
          organizationId
        }; userId: ${
          store.currentUser && store.currentUser.id
        }; entityId: ${
          store.currentEntity && store.currentEntity.id
        };`,
      }, { store });
    }

    const MessageListContainerClass = !showBanner ? "MessageListContainerDefault" : "MessageListContainerWithBanner";

    const validateRenders = () => {
      // TODO: quando a parte de aprovação no back estiver pronta, rever essa validação ao integrar.
      if (location.query.type === 'messagesToApprove' && isMessageApprover) {
        return this.renderApprovalRow;
      } else if (location.query.type === 'messagesToApprove' && isMessageWriter) {
        return this.renderWritterRow;
      } else if (!location.query.type) {
        if ((!location?.query?.folder || location?.query?.folder !== 'DELETED')) {
          return this.renderRow;
        }

        return this.renderTrashRow;
      }

      return this.renderDraftRow;
    }

    const validateIfShowApprovalMessages = () => {
      if (location.query.type === 'messagesToApprove' || location.query.type === 'messagesInApproval') {
        return true;
      }

      return false;
    }

    /* if (this.state.shouldShowConversationModal) {
      return (
        <ChatNews store={this.props.store} router={this.props.router} onClose={()=> this.setState({shouldShowConversationModal: false})} />
      )
    } */

    return (
      <div id="EntityMessages">
        <div style={showBanner ? { display: 'flex', flexDirection: 'row', justifyContent: 'center' } : null}>
          <div className={MessageListContainerClass}>
            {
              showFeatureToogle &&
              <FeatureToogleBanner
                iconColor='#C2DBFF'
                iconName='gear'
                iconClassName='duotone'
                backgroundColor='#F2F7FF'
                mainText={store.currentOrganization && utils.checkIfItIsClientOrSchool(store.currentOrganization.type) === 1 ? __('Do you want to enable more than one reply for students and guardians?') : __('Do you want to enable more than one reply for clients?')}
                optionTextColor='#084FFF'
                declineText={__('No, thanks')}
                acceptText={__('I want to enable!')}
                handleAccept={() => {
                  sendGoogleAnalyticsEvent({
                    name: 'FeatureToggle',
                    category: `accepted-${this.props.store.app.env}`,
                    label: `OrganizationId: ${
                      organizationId
                    }; userId: ${
                      store.currentUser && store.currentUser.id
                    }; entityId: ${
                      store.currentEntity && store.currentEntity.id
                    };`,
                  }, { store });

                  if (store.currentOrganization) updateOrganization({
                    variables: {
                      updateOrganizationMutation: {
                        id: store.currentOrganization.id,
                        confRepliesStudent: 1
                      }
                    }
                  });
                  this.handleFeatureToogleCookie();
                }
                }
                handleDecline={() => this.handleFeatureToogleCookie()}
              />
            }
            <NewTerms />
            {
              panes?.length > 1 &&
              <Tab
                menu={{ secondary: true, pointing: true, color: 'blue' }}
                panes={panes}
                onTabChange={this.onTabChange}
                activeIndex={panes.find(pane => pane.value === (location.query.type || '')).index}
                className="scrollbar-hide"
                style={this.isMobile() ? styles.mobileTab : { margin: !location.query.type && activeType ? '0px 0px 24px 36px' : '0px 0px 24px 0px' }}
              />
            }
            {
              !location.query.type && activeType &&
              <div style={{ display: 'flex', marginBottom: 24 }}>
                <MessageFilter
                  location={this.props.location}
                  request={entitySearchQuery}
                  id={params.entity_id}
                  onOpenFilters={() => this.setState({ margin: true })}
                  onCloseFilters={() => this.setState({ margin: false })}
                  resultExtract={{ edgeName: 'recipients' }}
                  style={{ display: 'grid', width: '100%' }}
                  selection={this.userHasEntity() && <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px 5px'
                    }}
                  >
                    <ColoredCheckbox
                      data-action="select-all-messages"
                      name="message"
                      checked={!!data?.node.selected}
                      onClick={this.onSelectAll}
                      style={{ margin: 'auto' }}
                      indeterminate={(selected?.length > 0)}
                    />
                  </div>}
                  organization={data?.node.organization}
                />
              </div>
            }
            {location.query.type === 'messagesToApprove' && isMessageApprover && (
              <MultipleAutosuggest
                apiExtract={{ name: 'fullname' }}
                resultExtract={{ edgeName: 'entities' }}
                style={{ image: true, icon: 'user' }}
                placeholder={__('Search by sender...')}
                icon="at"
                className="icon-left custom-label round-corners small-suggestion-avatars suggestion-dont-break-line"
                cssStyle={{ marginBottom: 24 }}
                modalCallbacks={{
                  onOpen: (el) => store.appends.push(el),
                  onClose: () => store.appends.pop(),
                }}
                initiallySelectedValues={location.query.recipientIds
                  ? JSON.parse(location.query.recipientIds)
                  : undefined}
                fetchInitialData={({ ids }) => fetchStaffList({
                  entityIds: ids,
                  limit: ids.length,
                  organizationId,
                  apolloClient: client,
                })}
                fetchData={({ search, limit }) => fetchStaffList({
                  search,
                  limit,
                  organizationId,
                  apolloClient: client,
                })}
                handleSelectedItems={items => {
                  updateLocationQueryRecipientIds(location, items);
                  data.refetch();
                }}
              />
            )}
            {
              activeType?.nodes?.length || validateIfShowApprovalMessages() ?
                <ListView
                  isMessageToApprove={location.query.type === 'messagesToApprove'}
                  renderRow={validateRenders()}
                  source={activeType?.nodes}
                  pagination={{
                    graphql: true,
                    hasNextPage: activeType?.pageInfo && activeType?.pageInfo?.hasNextPage,
                    loading: data?.loading
                  }}
                  onLoadMore={() => {
                    this.loadMore(location.query.type || 'messages');

                    sendGoogleAnalyticsEvent({
                      name: 'LoadMore',
                      category: `userId: ${
                        store.currentUser && store.currentUser.id
                      }; entityId: ${
                        store.currentEntity && store.currentEntity.id
                      }; currentResults: ${
                        activeType?.nodes?.length
                      }`,
                    }, { store });
                  }
                  }
                  segment={false}
                  celled={false}
                  style={{ margin: 0, marginBottom: this.state.margin ? '20rem' : 0, backgroundColor: 'white' }}
                  emptyMessage={''}
                />
                :
                !Object.keys(location.query)?.length ?
                  <Segment basic textAlign="center">
                    <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-23.png')} centered />
                    <div style={{ color: '#9696a0' }}>{__('You have no new messages, enjoy your day!')}</div>
                  </Segment>
                  :
                  location.query.folder !== 'DELETED' ?
                    <Segment
                      basic
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '450px'
                      }}
                    >
                      <Image style={{ maxHeight: 222 }} src={utils.asset('/images/ilustracoes-44.png')} centered />
                      <div style={{ marginTop: '15px' }}>
                        <p style={{ color: '#252628', fontSize: '20px', marginBottom: '5px', lineHeight: '30.5px' }}>
                          {__('No messages found')}
                        </p>
                        <p style={{ color: '#848685', fontSize: '15px', lineHeight: '30.5px' }}>
                          {__('What about trying a different text in the search box?')}
                        </p>
                      </div>
                    </Segment>
                    :
                    <Segment
                      basic
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '450px'
                      }}
                    >
                      <Image style={{ maxHeight: 400, marginBottom: '-50px' }} src={utils.asset('/images/ilustracoes-45.svg')} centered />
                      <div style={{ marginTop: 0 }}>
                        <p style={{ color: '#252628', fontSize: '20px', marginBottom: '5px', lineHeight: '30.5px' }}>
                          {__('No messages here')}
                        </p>
                        <p style={{ color: '#848685', fontSize: '15px', lineHeight: '30.5px' }}>
                          {__('Has anyone been taking things out of your bin?')}
                        </p>
                        <br />
                        <a
                          href="https://ajuda.classapp.com.br/hc/pt-br/articles/4410488275995"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <b>{__('See how it works')}</b>
                        </a>
                      </div>
                    </Segment>
            }

            {location.query.type === 'messagesToApprove' && isMessageApprover && !data?.node?.messagesToApprove?.totalCount &&
              <Segment
                basic
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '450px',
                  marginTop: '60px'
                }}
              >
                <Image style={{ maxHeight: 400 }} src={utils.asset('/images/empty-approve-message.png')} centered />
                <div>
                  <p style={{ color: '#252628', fontSize: '20px', marginBottom: '5px', lineHeight: '30.5px' }}>
                    {__('No message left to approve')}
                  </p>
                  <p style={{ color: '#848685', fontSize: '15px', lineHeight: '30.5px' }}>
                    {__('Looks like your work here is done  🎉')}
                  </p>
                </div>
              </Segment>
            }

            {!!selected?.length &&
              <Menu inverted attached="top" style={styles.menu}>
                <Menu.Item icon="envelope" content={__('Total') + ': ' + selected?.length} />
                {items.map(item => item)}
              </Menu>
            }

            {this.props.children && React.cloneElement(this.props.children,
              {
                super: {
                  setLoading: value => this.setState({ loading: value }),
                  refetch: this.props.data.refetch,
                  resetStore: this.props.client.resetStore
                }
              }
            )}
          </div>

          {showBanner &&
            <SideBanner
              url={utils.asset(banner.url)}
              disclaimer={banner.disclaimer}
              handleCloseClick={
                () => {
                  sendGoogleAnalyticsEvent({
                    name: 'RightBanner',
                    category: `close-${banner.key}-${store.app.env}`,
                    label: `OrganizationId: ${
                      organizationId
                    }; userId: ${
                      store.currentUser && store.currentUser.id
                    }; entityId: ${
                      store.currentEntity && store.currentEntity.id
                    };`,
                  }, { store });

                  this.saveBannerView(banner.version, currentUser.id);
                }
              }
              handleImageClick={() => this.setState({ showModal: true })}
            />
          }
          {showModal && (
            <BetaModal
              header={__('Deseja falar com um especialista?')}
              headerItemStyle={{ alignItems: 'baseline' }}
              onClose={() => {
                sendGoogleAnalyticsEvent({
                  name: 'RightBanner',
                  category: `close-modal-${banner.key}-${store.app.env}`,
                  label: `OrganizationId: ${
                    organizationId
                  }; userId: ${
                    store.currentUser && store.currentUser.id
                  }; entityId: ${
                    store.currentEntity && store.currentEntity.id
                  };`,
                }, { store });

                this.setState({ showModal: false });
              }}
              content={__('Ao clicar em "Confirmar", um especialista do time ClassApp entrará em contato.')}
              actions={(
                <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                  <span
                    data-action="cancel"
                    onClick={() => {
                      this.setState({ showModal: false });
                      sendGoogleAnalyticsEvent({
                        name: 'RightBanner',
                        category: `dismiss-modal-${banner.key}-${this.props.store.app.env}`,
                        label: `OrganizationId: ${
                          organizationId
                        }; userId: ${
                          store.currentUser && store.currentUser.id
                        }; entityId: ${
                          store.currentEntity && store.currentEntity.id
                        };`,
                      }, { store });

                      this.saveBannerView(banner.version, currentUser.id);
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      padding: '0px 1.15em 0px'
                    }}
                  >
                    {__('Cancel')}
                  </span>
                  <Button
                    data-action="submit"
                    primary
                    circular
                    full
                    content={__("Confirm")}
                    icon="check"
                    style={{ backgroundColor: '#0168FF', padding: '15px' }}
                    onClick={
                      () => onClickBetaModalActionButton(this.props, banner, this.saveBannerView)
                    }
                  />
                </div>
              )}
            />
          )}
        </div>
        {canSeeFeatureAnnouncement(this.props) && this.renderAnnouncementModal()}
      </div>
    );
  }
}
