export function getMarkersStateFromNode(accessNetwork) {
  if (!accessNetwork) return null;
  return {
    markers: accessNetwork.markers.ids,
    excludeMarkers: [],
    canAccessAllMarkers: false,
    markersTotalCount: 0,
    organizations: accessNetwork.organizations.ids,
    excludeOrganizations: [],
    canAccessAllOrganizations: false,
    organizationsTotalCount: 0,
  };
}
