import React, { Component } from 'react';
import { Icon, Table, Popup, Checkbox } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import { __ } from '../../i18n';

import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';
import Form from '../../components/Form';

@inject('store')
class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: 'ORDER3',
      value: [
        'fullname',
        'type',
        'groups',
        'description',
        'eid',
        'email',
        'status',
        'phone',
      ].concat(
        this.props.store.currentOrganization?.unleashStatus
          ?.new_student_flow && ['birth_date', 'document_number'],
      ),
      groupOptions: true,
    };
  }

  tableItems() {
    let header = null;
    let rows = null;
    if (this.state.optionSelected === 'ORDER2') {
      header = [__('Name'), ' ... ', __('Groups')];
      rows = [
        [
          __('Mary'),
          ' ... ',
          `${__('1st year')} / ${__('Capoeira')} / ${__('Swimming')}`,
        ],
        [__('John'), ' ... ', __('1st year')],
        [__('Peter'), ' ... ', `${__('2st year')} / ${__('Swimming')}`],
      ];
    } else if (this.state.optionSelected === 'ORDER1') {
      header = [
        __('Name'),
        ' ... ',
        __('1st year'),
        __('2st year'),
        __('Capoeira'),
        __('Swimming'),
      ];
      rows = [
        [
          __('Mary'),
          ' ... ',
          __('1st year'),
          '   ',
          __('Capoeira'),
          __('Swimming'),
        ],
        [__('John'), ' ... ', __('1st year'), '   ', '  ', '  '],
        [__('Peter'), ' ... ', '   ', __('2st year'), '  ', __('Swimming')],
      ];
    } else {
      header = [__('Name'), ' ... ', __('Groups'), __('Groups'), __('Groups')];
      rows = [
        [__('Mary'), ' ... ', __('1st year'), __('Capoeira'), __('Swimming')],
        [__('John'), ' ... ', __('1st year'), '  ', '  '],
        [__('Peter'), ' ... ', __('2st year'), __('Swimming'), '  '],
      ];
    }

    return { header, rows };
  }

  handleSubmit() {
    let { exportLink } = this.props.location.state;
    const { value, groupOptions, optionSelected } = this.state;
    exportLink += `&columns=${value.toString()}&groupExportOption=${groupOptions ? optionSelected : ''}`;

    if (!window.navigator.onLine) {
      this.props.store.snackbar = {
        active: true,
        message: __('No internet connection'),
        success: false,
      };
      return;
    }

    try {
      if (exportLink) window.open(exportLink);
      this.props.router.goBack();
      setTimeout(() => {
        this.props.store.snackbar = {
          active: true,
          message: __('Data successfully exported'),
          success: true,
        };
      }, 800);
    } catch (e) {
      this.props.store.snackbar = {
        active: true,
        message: __('Error while exporting data'),
        success: false,
      };
    }
  }

  handleFields(data, field) {
    let checkedFields = [];
    let groupOptions = this.state.groupOptions;
    const { value } = this.state;
    if (data.checked) {
      value.push(field.key);
      checkedFields = value;
      if (field.key === 'groups') groupOptions = true;
    } else {
      checkedFields = value.filter((item) => item !== field.key);
      if (field.key === 'groups') groupOptions = false;
    }
    this.setState({ value: checkedFields, groupOptions });
  }

  renderCheckbox(fields) {
    const isNewStudentFlowEnabled =
      this.props.store.currentOrganization?.unleashStatus?.new_student_flow;

    const disabledCheckbox = isNewStudentFlowEnabled ? [0, 1, 2] : [0];

    const popupContent = isNewStudentFlowEnabled
      ? __(
        'The fields name, birth date and CPF will always be exported to the spreadsheet.',
      )
      : __('Names will always be exported to the spreadsheet.');

    return fields.map((field, index) => (
      <Popup
        key={index}
        content={popupContent}
        disabled={!disabledCheckbox.includes(index)}
        trigger={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              whiteSpace: 'nowrap',
              alignItems: 'center',
              margin: '25px 25px 0 0',
            }}
          >
            <Checkbox
              data-testid={`export-people-with-${field.key}`}
              onChange={(e, data) => this.handleFields(data, field)}
              defaultChecked
              disabled={disabledCheckbox.includes(index)}
              style={{
                paddingRight: '30px',
              }}
            />
            <p>{field.text}</p>
          </div>
        }
      />
    ));
  }

  render() {
    const { groupOptions } = this.state;
    const { header, rows } = this.tableItems();

    const isNewStudentFlowEnabled =
      this.props.store.currentOrganization?.unleashStatus?.new_student_flow;

    const fields = [
      { text: __('Name'), key: 'fullname' },
      isNewStudentFlowEnabled && { text: __('Birth date'), key: 'birth_date' },
      isNewStudentFlowEnabled && { text: __('CPF'), key: 'document_number' },
      { text: __('Group'), key: 'groups' },
      { text: __('External ID'), key: 'eid' },
      { text: __('Status'), key: 'status' },
      { text: __('Profile'), key: 'type' },
      { text: __('Description'), key: 'description' },
      { text: __('Email'), key: 'email' },
      { text: __('Phone'), key: 'phone' },
    ].filter(Boolean);

    return (
      <BetaModal
        id={'ExportOrganization'}
        header={__('Export')}
        size="small"
        onClickTimesClose={() => this.props.router.goBack()}
        content={
          <Form>
            <label style={{ fontSize: '16px', alignSelf: 'center' }}>
              {__('Exported Columns')}
            </label>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                justifyContent: 'space-between',
              }}
            >
              {this.renderCheckbox(fields)}
            </div>
            <div
              className={groupOptions ? ' ... ' : 'disable-text'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '50px',
                marginBottom: '30px',
              }}
            >
              <p style={{ fontSize: '16px', marginBottom: '20px' }}>
                {__('Group Formatting')}
              </p>
              <div
                className="radio-button-container"
                onClick={() => this.setState({ optionSelected: 'ORDER3' })}
              >
                <Form.Checkbox
                  style={{ alignSelf: 'center' }}
                  disabled={!groupOptions}
                  radio
                  radioChecked
                  name="attach"
                  value
                  checked={
                    this.state.optionSelected === 'ORDER3' && groupOptions
                  }
                  color="#084FFF"
                />
                <span style={{ marginLeft: '8px', cursor: 'pointer' }}>
                  <p className="radio-title">
                    {__('Groups separated by columns')}
                  </p>
                  <p style={{ fontSize: '12px' }}>
                    {__(
                      'Regular groups in one column and extras in another. Recommended for data update',
                    )}
                  </p>
                </span>
              </div>
              <div
                className="radio-button-container"
                onClick={() => this.setState({ optionSelected: 'ORDER2' })}
              >
                <Form.Checkbox
                  style={{ alignSelf: 'center' }}
                  disabled={!groupOptions}
                  radio
                  radioChecked
                  name="attach"
                  value
                  checked={
                    this.state.optionSelected === 'ORDER2' && groupOptions
                  }
                  color="#084FFF"
                />
                <span style={{ marginLeft: '8px', cursor: 'pointer' }}>
                  <p className="radio-title">{__('All groups in a column')}</p>
                  <p style={{ fontSize: '12px' }}>
                    {__(
                      'All the entity’s groups in a single column, separated by “/”. Recommended for group management',
                    )}
                  </p>
                </span>
              </div>
              <div
                className="radio-button-container"
                onClick={() => this.setState({ optionSelected: 'ORDER1' })}
              >
                <Form.Checkbox
                  style={{ alignSelf: 'center' }}
                  disabled={!groupOptions}
                  radio
                  radioChecked
                  name="attach"
                  value
                  checked={
                    this.state.optionSelected === 'ORDER1' && groupOptions
                  }
                  color="#084FFF"
                />
                <span style={{ marginLeft: '8px', cursor: 'pointer' }}>
                  <p className="radio-title">{__('One column per group')}</p>
                  <p style={{ fontSize: '12px' }}>
                    {__(
                      'Our old spreadsheet model. Each column will represent a specific group',
                    )}
                  </p>
                </span>
              </div>
            </div>
            <div style={{ display: groupOptions ? 'inline' : 'none' }}>
              <p style={{ fontSize: '16px', marginBottom: '20px' }}>
                {' '}
                <Icon name="table" />
                {__('Example')}
              </p>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {
                  <Table celled collapsing>
                    <Table.Header>
                      <Table.Row>
                        {header.map((item, index) => (
                          <Table.HeaderCell key={index}>
                            {item}
                          </Table.HeaderCell>
                        ))}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {rows.map((item, index) => (
                        <Table.Row key={index}>
                          {item.map((cell, i) => (
                            <Table.Cell key={i}>{cell}</Table.Cell>
                          ))}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                }
              </div>
            </div>
          </Form>
        }
        actions={[
          <BetaButton
            floated="left"
            round
            transparent
            text={__('Cancel')}
            onClick={() => this.props.router.goBack()}
          />,
          <BetaButton
            data-action="submit"
            round
            loading={this.state.loading}
            floated="right"
            text={__('Export')}
            onClick={() => this.handleSubmit()}
          />,
        ]}
      />
    );
  }
}

export default Export;
