import React from 'react';
import { Menu } from 'semantic-ui-react';

import ColoredCheckbox from '../../../components/ColoredCheckbox';
import { __ } from '../../../i18n';

export function SelectAllOption({ isMobile, onSelectAll, isChecked }) {
  const mobileStyles = {
    justifyContent: 'space-between',
    borderBottom: '1px solid #f1f3f5',
    color: '#084FFF',
  };

  return (
    <Menu.Menu key="all" className="item" style={isMobile ? mobileStyles : {}}>
      {isMobile && (
        <span
          style={{ fontWeight: 400, fontSize: '16px', cursor: 'pointer' }}
          onClick={onSelectAll}
        >
          {__('Select all groups')}
        </span>
      )}
      <ColoredCheckbox
        name="entity"
        data-action="select-all"
        radio
        radioChecked
        bold={false}
        color="#084FFF"
        checked={isChecked}
        onClick={onSelectAll}
        style={{ verticalAlign: 'middle', marginRight: '10px' }}
      />
      {!isMobile && (
        <span
          style={{ fontWeight: 400, fontSize: '16px', cursor: 'pointer' }}
          onClick={onSelectAll}
        >
          {__('Select All')}
        </span>
      )}
    </Menu.Menu>
  );
}
