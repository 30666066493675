import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment';

import Controller from '../../../components/Controller';
import Modal from '../../../components/Modal';

import EntityForm from '../../Entity/Form';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';
import { getAccessNetwork } from './helpers/getAccessNetwork';

import { isNetwork } from '../../../app/Domain/UseCases/Unleash/validations/IsNetwork';

const createPicture = (entityId) => `mutation createPicture {
  createPicture(input: { entityId: ${entityId} }) {
    clientMutationId
  }
}`;

@inject('store', 'client', 'api')
@graphql(
  gql`
    query EntityAddQuery($id: ID!) {
      node(id: $id) @connection(key: "Organization", filter: ["id"]) {
        ... on Organization {
          id: dbId
          type
          confCharge
          plan
          premiumPeriod
          isDemonstration
          monthlyMessagesCount
          monthlyMessageQuota
          groups(limit: 400) {
            totalCount
            nodes {
              type
              name
              id: dbId
            }
          }
          tags(limit: 400) {
            nodes {
              name
              checked
              id: dbId
            }
          }
          features {
            messageManager
            moments
            reports
            api
            charges
            deleteMessages
            editMessages
            forms
            links
          }
        }
      }
    }
  `,
  {
    options: (ownProps) => ({
      variables: {
        id: ownProps.params.organization_id,
      },
    }),
  },
)
@graphql(gql`
  mutation createEntity($createEntityMutation: CreateEntityInput!) {
    createEntity(input: $createEntityMutation) {
      clientMutationId
      entity {
        id: dbId
      }
    }
  }
`)
@graphql(
  gql`
    mutation updateEntity($updateEntityMutation: UpdateEntityInput!) {
      updateEntity(input: $updateEntityMutation) {
        clientMutationId
      }
    }
  `,
  {
    name: 'updateEntity',
    options: {
      refetchQueries: ['OrganizationEntitiesQuery', 'OrganizationGroupsQuery'],
    },
  },
)
@observer
export default class EntityAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add person');
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, client, params } = nextProps;

    if ((data.loading && !data.node) || !data.node) return;

    const { totalCount, nodes } = data.node.groups;

    if (totalCount === nodes.length) return;

    try {
      const response = await client.query({
        query: gql`
          query EntityAddGroupsQuery($id: ID!, $limit: Int) {
            node(id: $id) {
              ... on Organization {
                id: dbId
                groups(limit: $limit) {
                  nodes {
                    type
                    name
                    id: dbId
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: params.organization_id,
          limit: totalCount,
        },
      });

      data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        nextResult.node.groups.nodes = response.data.node.groups.nodes;

        return nextResult;
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateEntity = async ({ values, id }) => {
    try {
      await this.props.updateEntity({
        variables: {
          updateEntityMutation: {
            id,
            fullname: values.fullname,
          },
        },
      });
      this.props.store.snackbar = {
        active: true,
        message: __('%s was added', values.fullname),
        success: true,
      };
      this.props.router.goBack();
    } catch (err) {
      this.setState({
        errors: { fullname: utils.handleError(err.graphQLErrors[0]) },
      });
    }
  };

  async uploadPicture({ data, picture, values, api, store }) {
    try {
      if (picture) {
        await api.upload('picture', {
          file: picture,
          query: createPicture(data.createEntity.entity.id),
        });
      }
      return this.updateEntity({
        values: values,
        id: data.createEntity.entity.id,
      });
    } catch (err) {
      store.snackbar = {
        active: true,
        message: utils.handleError(err.graphQLErrors[0]),
        success: false,
      };
    }
  }

  request = async (values) => {
    const { store, api } = this.props;
    const { location } = this.props;
    const query = { ...location.query };
    const entityValues = omit(
      values,
      'picture',
      'communicationToAll',
      'markersState',
      Object.keys(query),
    );
    const { picture, markersState, communicationToAll } = values;
    let birthDate;

    if (values.birthDate) {
      birthDate =  moment(values.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    const variables = {
      createEntityMutation: {
        organizationId: this.props.params.organization_id,
        updateIfExists: false,
        ...entityValues,
        birthDate,
      },
    };

    if (isNetwork(store?.currentOrganization)) {
      const accessNetwork = getAccessNetwork(
        markersState,
        communicationToAll || values.type === 'ADMIN',
      );
      variables.createEntityMutation.accessNetwork = accessNetwork;
    }
  
    try {
      const mutateAdd = await this.props.mutate({
        variables,
      });
      if(mutateAdd && mutateAdd.data){
        await this.uploadPicture({
          data: mutateAdd.data,
          picture,
          values: entityValues,
          api,
          store,
        });
      }
    } catch (err) {
      if(entityValues.type == 'STUDENT'){
        const studentError = utils.getErrorForStudent(err.graphQLErrors[0])
        if(studentError) {
          err.modal = studentError.modalError
          err.inputErrors = studentError.inputErrors
        }
      }
      throw err;
    }
  };

  render() {
    const { data, params } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;
    return (
      <Controller
        id="EntityAdd"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false,
        }}
        modalType={'beta'}
        add
        title={this.isMobile() ? null : __('Add person')}
        submitButtonIcon="check"
        form={EntityForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        submitButton={{
          text: __('Add'),
          isActionButtom: true,
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true,
        }}
        formProps={{
          organization: {
            id: params.organization_id,
            type: data.node.type,
            groups: data.node.groups.nodes,
            tags: data.node.tags.nodes,
            features: data.node.features,
            confCharge: data.node.confCharge,
            plan: data.node.plan,
            premiumPeriod: data.node.premiumPeriod,
            monthlyMessagesCount: data.node.monthlyMessagesCount,
            monthlyMessageQuota: data.node.monthlyMessageQuota,
          },
        }}
        {...this.props}
      />
    );
  }
}
