import React from 'react';
import { render } from '../../../../newRender';
import { __ } from '../../../../i18n';

import { SelectAllOption } from '../SelectAllOption';

describe('SelectAllOption', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      isMobile: false,
      onSelectAll: jest.fn(),
      isChecked: false,
    };
  });

  it('should render SelectAllOption', () => {
    const { getByText } = render(<SelectAllOption {...props} />);
    expect(getByText(__('Select All'))).toBeInTheDocument();
  });

  it('should render select all options when isMobile is true and shouldHideSelectAll is false', () => {
    props.isMobile = true;

    const { getByText } = render(<SelectAllOption {...props} />);
    expect(getByText(__('Select all groups'))).toBeInTheDocument();
  });
});
