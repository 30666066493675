import { isEntitySelected } from '../isEntitySelected';

describe('isEntitySelected', () => {
  let props;

  beforeEach(() => {
    props = {
      entityId: 'entityId',
      entityGroups: [
        {
          id: 'entityGroupId',
        },
      ],
      allGroups: [
        {
          id: 'entityGroupId',
          selected: true,
          entities: {
            nodes: [],
          },
        },
      ],
      entityGroupIsSelected: false,
    };
  });

  it('should return true when entityGroupIsSelected is true', () => {
    props.entityGroupIsSelected = true;

    const result = isEntitySelected(
      props.sentityId,
      props.entityGroups,
      props.allGroups,
      props.entityGroupIsSelected,
    );

    expect(result).toBe(true);
  });

  it('should return false when entityGroups is less than or equal to 1', () => {
    props.entityGroups = [];

    const result = isEntitySelected(
      props.entityId,
      props.entityGroups,
      props.allGroups,
      props.entityGroupIsSelected,
    );

    expect(result).toBe(false);
  });

  it('should return true when entity is selected', () => {
    props.allGroups[0].entities.nodes = [
      {
        id: props.entityId,
      },
    ];

    const result = isEntitySelected(
      props.entityId,
      props.entityGroups,
      props.allGroups,
      props.entityGroupIsSelected,
    );

    expect(result).toBe(true);
  });

  it('should return false when entity is not selected', () => {
    props.allGroups[0].entities.nodes = [
      {
        id: 'anotherEntityId',
      },
    ];

    const result = isEntitySelected(
      props.entityId,
      props.entityGroups,
      props.allGroups,
      props.entityGroupIsSelected,
    );

    expect(result).toBe(false);
  });
});
