import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment';

import Controller from '../../../components/Controller';
import Modal from '../../../components/Modal';

import EntityForm from '../../Entity/Form';

import { getMarkersStateFromNode } from './helpers/getMarkersStateFromNode';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

import { getEditAccessNetwork } from './helpers/getEditAccessNetwork';

import { isNetwork } from '../../../app/Domain/UseCases/Unleash/validations/IsNetwork';

const createPicture = (entityId) => `mutation createPicture {
  createPicture(input: { entityId: ${entityId} }) {
    clientMutationId
  }
}`;

@inject('store', 'client', 'api')
@graphql(
  gql`
    query EntityEditQuery($id: ID!, $networkId: ID) {
      node(id: $id) @connection(key: "Entity", filter: ["id"]) {
        ... on Entity {
          id: dbId
          fullname
          type
          birthDate
          documentNumber
          disabled
          eid
          seeAll
          invisible
          description
          picture {
            uri
            id: dbId
            key
          }
          scope
          policy
          addresses {
            nodes {
              type
              address
              tags {
                nodes {
                  id: dbId
                  name
                }
              }
              user {
                id: dbId
              }
            }
          }
          roles(limit: 400) {
            nodes {
              id: dbId
              name
              type
            }
          }
          organization {
            id: dbId
            type
            confCharge
            plan
            premiumPeriod
            isDemonstration
            monthlyMessagesCount
            monthlyMessageQuota
            groups(limit: 400) {
              totalCount
              nodes {
                type
                name
                id: dbId
              }
            }
            tags(limit: 400) {
              nodes {
                name
                checked
                id: dbId
              }
            }
            features {
              messageManager
              moments
              reports
              api
              charges
              deleteMessages
              editMessages
              forms
              links
            }
          }
          accessNetwork(networkId: $networkId) {
            communicationToAll
            markers {
              ids
            }
            organizations {
              ids
            }
          }
        }
      }
    }
  `,
  {
    options: (ownProps) => ({
      variables: {
        id: ownProps.params.entity_id,
        networkId: ownProps.store?.currentOrganization?.network?.id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation updateEntity($updateEntityMutation: UpdateEntityInput!) {
      updateEntity(input: $updateEntityMutation) {
        clientMutationId
      }
    }
  `,
  {
    options: {
      refetchQueries: [
        'OrganizationEntitiesQuery',
        'EntityEditQuery',
        'OrganizationGroupsQuery',
        'EntityAccountsQuery',
        'OrganizationNodeIdQuery',
      ],
    },
  },
)
@graphql(
  gql`
    mutation deletePicture($deletePictureMutation: DeletePictureInput!) {
      deletePicture(input: $deletePictureMutation) {
        clientMutationId
      }
    }
  `,
  {
    name: 'deletePicture',
  },
)
@observer
export default class EntityEdit extends Controller {
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, client } = nextProps;
    if ((data.loading && !data.node) || !data.node || !data.node.organization)
      return;

    const organization = data.node.organization;

    if (
      organization &&
      organization.groups &&
      organization.groups.totalCount === organization.groups.nodes.length
    )
      return;

    try {
      const response = await client.query({
        query: gql`
          query EntityEditGroupsQuery($id: ID!, $limit: Int) {
            node (id: $id) {
              ... on Organization {
                id: dbId
                groups (limit: $limit) {
                  nodes {
                    type
                    name
                    id: dbId
                  }
                }
              }
            }
          }`,
        variables: {
          id: organization.id,
          limit: organization.groups.totalCount
        }
      });


      data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        nextResult.node.organization.groups.nodes = response.data.node.groups.nodes;

        return nextResult;
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateEntity = async (values) => {
    const { store, client } = this.props;
    const entityValues = omit(values, 'markersState', 'communicationToAll');

    const { markersState, communicationToAll } = values;

    const variables = {
      updateEntityMutation: {
        id: this.props.params.entity_id,
        ...entityValues,
        defaultFeatures: undefined,
      },
    };

    if (isNetwork(store?.currentOrganization)) {
      const accessNetwork = getEditAccessNetwork(
        markersState,
        communicationToAll || values.type === 'ADMIN',
      );

      await client.mutate({
        mutation: gql`
          mutation UpdateEntityAccessNetworkMutation(
            $entityId: ID!
            $networkId: ID!
            $organizations: OrganizationsField
            $markers: MarkersField
            $communicationToAll: Boolean!
          ) {
            UpdateEntityAccessNetworkMutation(
              input: {
                entityId: $entityId
                networkId: $networkId
                organizations: $organizations
                markers: $markers
                communicationToAll: $communicationToAll
              }
            ) {
              success
            }
          }
        `,
        variables: {
          entityId: this.props.params.entity_id,
          networkId: store?.currentOrganization?.network?.id,
          ...accessNetwork,
        },
      });
    }

    return this.props
      .mutate({
        variables,
      })
      .then(() => {
        const messageSnackbar = {
          saved: __('%s was saved', values.fullname),
          disabled: __('%s successfully disabled', values.fullname),
          enabled: __('%s successfully enabled', values.fullname),
        };
        const messageSelect =
          values.disabled === this.prevDisabled
            ? 'saved'
            : values.disabled
              ? 'disabled'
              : 'enabled';

      this.props.store.snackbar = { active: true, message: messageSnackbar[messageSelect], success: true };
      this.props.router.goBack();
    }).catch((err) => {
      const error = utils.handleError(err.graphQLErrors[0]);
      const errors = {};

      if (err.graphQLErrors[0].message.indexOf('address') > -1) {
        errors.address = error;
      } else {
        errors.fullname = error;
      }

      this.setState({ errors });
      throw err;
    });
  };

  request = async (values) => {
    const { api } = this.props;

    const { location } = this.props;
    const query = { ...location.query };
    const entityValues = omit(values, 'picture', Object.keys(query));
    const picture = values.picture;

    const addPicture = picture && picture instanceof Blob;
    const deletePicture =
      !picture &&
      this.props.data.node.picture &&
      this.props.data.node.picture.id;

    let birthDate;
    let documentNumber;

    if (this.props.store.currentOrganization?.unleashStatus?.new_student_flow) {
      birthDate = values.birthDate && moment(values.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      documentNumber = values.documentNumber;
    }

    if (addPicture) {
      return api
        .upload("picture", {
          file: picture,
          query: createPicture(this.props.params.entity_id),
        })
        .then(() => this.updateEntity({ ...entityValues, birthDate, documentNumber }));
    }

    if (deletePicture) {
      return this.props
        .deletePicture({
          variables: {
            deletePictureMutation: { entityId: this.props.params.entity_id },
          },
        })
        .then(() => this.updateEntity({ ...entityValues, birthDate, documentNumber }));
    }

    try{
      return await this.updateEntity({ ...entityValues, birthDate, documentNumber });
    }catch(err) {
      if(entityValues.type == 'STUDENT'){
        const studentError = utils.getErrorForStudent(err.graphQLErrors[0])
        if(studentError) {
          err.modal = studentError.modalError
          err.inputErrors = studentError.inputErrors
        }
      }
      throw err;
    }
  };

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const {
      id,
      addresses,
      eid,
      fullname,
      birthDate,
      documentNumber,
      roles,
      invisible,
      organization,
      seeAll,
      type,
      disabled,
      description,
      picture,
      scope,
      policy,
      accessNetwork,
    } = data.node;
    this.prevDisabled = disabled;

    return (
      <Controller
        id="EntityEdit"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false,
        }}
        modalType={"beta"}
        edit
        title={__("Edit person")}
        submitButtonIcon="check"
        form={EntityForm}
        values={{
          id,
          fullname,
          type,
          eid,
          seeAll,
          invisible,
          disabled,
          description,
          picture,
          birthDate: birthDate && moment.utc(birthDate).locale('pt').format('DD/MM/YYYY'),
          documentNumber,
          groups: (roles && roles.nodes) || [],
          addresses: utils
            .removeDuplicateAddresses(addresses.nodes)
            .map((address) => ({
              address: address.address,
              type: address.type,
              tags: address.tags.nodes.map((a) => ({ id: a.id, name: a.name })),
              user: address.user,
            })),
          scope: utils.parseScopeToForm(scope),
          policy,
          markersState: getMarkersStateFromNode(accessNetwork),
          communicationToAll: accessNetwork?.communicationToAll || false,
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        submitButton={{
          text: __("Save"),
          isActionButtom: true,
        }}
        cancelButton={{
          text: __("Cancel"),
          isActionButtom: true,
        }}
        formProps={{
          organization: organization && {
            id: organization && organization.id,
            type: organization && organization.type,
            groups:
              organization && organization.groups && organization.groups.nodes,
            tags: organization && organization.tags && organization.tags.nodes,
            features: organization && organization.features,
            confCharge: organization && organization.confCharge,
            plan: organization && organization.plan,
            premiumPeriod: organization && organization.premiumPeriod,
            monthlyMessagesCount:
              organization && organization.monthlyMessagesCount,
            monthlyMessageQuota:
              organization && organization.monthlyMessageQuota,
          },
          scope,
          policy,
          selectedMarkersIds: accessNetwork?.markers?.ids ?? [],
          selectOrganizationsIds: accessNetwork?.organizations?.ids ?? [],
        }}
        {...this.props}
      />
    );
  }
}
